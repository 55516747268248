import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SentinelService } from '@bannerflow/sentinel';

@Component({
    selector: 'app-root',
    imports: [RouterOutlet],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent {
    sentinel = inject(SentinelService);
}
