import { Component } from '@angular/core';
import { UIModule } from '@bannerflow/ui';

@Component({
    selector: 'app-sso-test',
    templateUrl: './sso.component.html',
    styleUrls: ['./sso.component.scss'],
    imports: [UIModule]
})
export class SsoComponent {}
