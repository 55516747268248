import { provideHttpClient } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAuth0 } from '@auth0/auth0-angular';

import { UIModule } from '@bannerflow/ui';
import { environment } from 'src/environments/environment';

import { routes } from './app.routes';
import { RegistrationModule } from './registration/registration.module';
import { SharedModule } from './shared/shared.module';
import { LoginModule } from './login/login.module';
import { provideSentinelService, withNewRelic, withLoggerConfig } from '@bannerflow/sentinel';

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(RegistrationModule, SharedModule, UIModule, LoginModule),
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(routes),
        provideAuth0({
            domain: environment.LOGIN_URL,
            clientId: environment.CLIENT_ID,
            authorizationParams: {
                redirect_uri: `${window.location.origin}${window.location.pathname}${window.location.search}`,
                audience: 'https://bannerflow.com/resources/',
                scope: 'openid profile email offline_access'
            },
            useRefreshTokens: true
        }),
        provideHttpClient(),
        provideSentinelService(
            withNewRelic({
                enabled: environment.NAME !== 'local',
                licenseKey: environment.NEW_RELIC_LICENSE_KEY,
                applicationId: environment.NEW_RELIC_APPLICATION_ID,
                accountId: environment.NEW_RELIC_ACCOUNT_ID,
                trustKey: environment.NEW_RELIC_TRUST_KEY,
                agentId: environment.NEW_RELIC_APPLICATION_ID,
                origins: [
                    environment.ACCOUNT_ACCESS_SERVICE_URL,
                    environment.BANNEFLOW_CLIENT_URL,
                    environment.BAU_SERVICE_URL
                ],
                applicationVersion: environment.VERSION,
                releaseName: environment.RELEASE_NAME,
                releaseId: environment.VERSION
            }),
            withLoggerConfig({
                level: 'debug',
                production: environment.NAME === 'production',
                sandbox: environment.NAME === 'sandbox',
                stage: environment.NAME
            })
        )
    ]
};
