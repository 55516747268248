export interface Environment {
    NAME: string;
    LOGIN_URL: string;
    CLIENT_ID: string;
    BANNEFLOW_CLIENT_URL: string;
    ACCOUNT_ACCESS_SERVICE_URL: string;
    BAU_SERVICE_URL: string;
    CONNECTION_HINT_MAP: Map<string, string>;

    RELEASE_NAME: string;
    VERSION: string;
    NEW_RELIC_TRUST_KEY: string;
    NEW_RELIC_ACCOUNT_ID: string;
    NEW_RELIC_APPLICATION_ID: string;
    NEW_RELIC_LICENSE_KEY: string;
}

export const PasswordConnectionHint = 'pw';
