<ui-loader *ngIf="isLoading"></ui-loader>

<div class="header text-centered">
    <title>Register to Bannerflow</title>
    <img
        src="assets/images/logotypes/logo_butterfly.svg"
        alt="Bannerflow logo" />

    <div class="welcome">Welcome to Bannerflow</div>
    <div class="subtitle"></div>
</div>

<div
    class="container"
    *ngIf="!isLoading">
    <form
        class="form__content"
        novalidate="novalidate"
        (keyup.enter)="onSubmit()"
        [formGroup]="newUserForm">
        <div class="row">
            <div class="column columnHalf">
                <div class="label">First name</div>
                <ui-input
                    formControlName="givenName"
                    id="givenName"
                    name="givenName"
                    placeholder="Enter your first name"
                    [validation]="newUserForm.controls.givenName"></ui-input>
                <div
                    class="error__message"
                    *ngIf="
                        !newUserForm.controls.givenName.value && newUserForm.controls.givenName.dirty
                    ">
                    <span>First name is required</span>
                </div>
                <div
                    class="error__message"
                    *ngIf="
                        newUserForm.controls.givenName.value.length > 0 &&
                        newUserForm.controls.givenName.value.length < 2 &&
                        !(
                            newUserForm.controls.familyName.value.length > 0 &&
                            newUserForm.controls.familyName.value.length < 2
                        )
                    ">
                    <span>First name should be at least two characters</span>
                </div>
            </div>
            <div class="column columnHalf">
                <div class="label">Last name</div>
                <ui-input
                    formControlName="familyName"
                    id="familyName"
                    name="familyName"
                    placeholder="Enter your last name"
                    [validation]="newUserForm.controls.familyName"></ui-input>
                <div
                    class="error__message"
                    *ngIf="
                        !newUserForm.controls.familyName.value && newUserForm.controls.familyName.dirty
                    ">
                    <span>Last name is required</span>
                </div>
                <div
                    class="error__message"
                    *ngIf="
                        newUserForm.controls.familyName.value.length > 0 &&
                        newUserForm.controls.familyName.value.length < 2 &&
                        !(
                            newUserForm.controls.givenName.value.length > 0 &&
                            newUserForm.controls.givenName.value.length < 2
                        )
                    ">
                    <span>Last name should be at least two characters</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="column columnHalf">
                <div class="label">Email</div>
                <div>
                    <ui-input
                        [disabled]="true"
                        formControlName="email"
                        id="email"
                        name="email"></ui-input>
                </div>
            </div>
            <div class="column columnHalf title">
                <div class="label">Title</div>
                <div
                    class="loading-item titles"
                    *ngIf="!isUserTitlesLoaded">
                    Loading titles...
                </div>
                <div *ngIf="isUserTitlesLoaded">
                    <ui-select
                        id="select-theme"
                        placeholder="Select your title"
                        [width]="'100%'"
                        [useTargetWidth]="true"
                        [(selected)]="selectedUserTitle">
                        <ui-option
                            *ngFor="let title of userTitles"
                            [value]="title"
                            >{{ title.name }}</ui-option
                        >
                    </ui-select>
                    <div
                        class="info"
                        uiTooltipMaxWidth="400px"
                        uiTooltipPosition="top"
                        uiTooltipTrigger="click"
                        [uiTooltipInteractive]="true"
                        uiTooltip="Your title is required in order for our support team to help you in the most efficient way and to give you suggestions on webinars or other things that might be relevant for you. Please select the closest title if your exact title is not presented in the list.">
                        Why we need this?
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="column">
                <div class="label">Time zone</div>
                <div>
                    <ui-select
                        id="select-theme"
                        [searchable]="true"
                        [width]="'100%'"
                        [useTargetWidth]="true"
                        [(selected)]="user.timeZone">
                        <ui-option
                            *ngFor="let itm of timeZones"
                            [value]="itm.id"
                            >{{ itm.displayName }}</ui-option
                        >
                    </ui-select>
                </div>
            </div>
        </div>

        <div
            class="row"
            *ngIf="isPasswordUser">
            <div class="column">
                <div class="label">Password</div>
                <div class="password-container">
                    <ui-input
                        class="password-field"
                        formControlName="password"
                        id="password"
                        name="password"
                        [type]="displayPassword ? 'text' : 'password'"
                        placeholder="Create a password"
                        [validation]="newUserForm.controls.password"
                        (click)="
                            onPasswordEvents(
                                'password',
                                confirmPasswordTarget,
                                passwordRequirementsPopover
                            )
                        "
                        (focus)="
                            onPasswordEvents(
                                'password',
                                confirmPasswordTarget,
                                passwordRequirementsPopover
                            )
                        "
                        (valueChange)="
                            onPasswordEvents(
                                'password',
                                confirmPasswordTarget,
                                passwordRequirementsPopover
                            )
                        "
                        ui-popover-target
                        #confirmPasswordTarget="ui-popover-target">
                    </ui-input>
                    <div
                        class="passwordToggle"
                        tabindex="0"
                        (click)="showPassword()"
                        (keyup.space)="showPassword()">
                        <ui-svg-icon
                            *ngIf="!displayPassword"
                            icon="visibility-hidden"></ui-svg-icon>
                        <ui-svg-icon
                            *ngIf="displayPassword"
                            icon="visibility-visible"></ui-svg-icon>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="row"
            *ngIf="isPasswordUser">
            <div class="column">
                <div class="label">Password confirmation</div>
                <div>
                    <ui-input
                        formControlName="passwordConfirmation"
                        id="passwordConfirmation"
                        name="passwordConfirmation"
                        [type]="displayPassword ? 'text' : 'password'"
                        placeholder="Enter your password again"
                        [validation]="newUserForm.controls.passwordConfirmation"
                        (click)="
                            onPasswordEvents(
                                'passwordConfirmation',
                                confirmPasswordTarget,
                                passwordRequirementsPopover
                            )
                        "
                        (focus)="
                            onPasswordEvents(
                                'passwordConfirmation',
                                confirmPasswordTarget,
                                passwordRequirementsPopover
                            )
                        "
                        (valueChange)="
                            onPasswordEvents(
                                'passwordConfirmation',
                                confirmPasswordTarget,
                                passwordRequirementsPopover
                            )
                        "
                        ui-popover-target
                        #confirmPasswordTarget="ui-popover-target"></ui-input>
                </div>
            </div>
        </div>

        <div class="row policy">
            <div>
                <ui-checkbox [(selected)]="user.acceptsToC"></ui-checkbox>
                <span class="checkbox-text"
                    >I accept the
                    <a
                        href="https://bannerflow.com/privacy"
                        tabindex="-1"
                        target="_blank"
                        title="Privacy policy">
                        privacy policy
                    </a>
                </span>
                <div
                    class="error__message"
                    *ngIf="!user.acceptsToC && submitTouched">
                    <span>Accepting Terms of Conditions and Privacy policy is required.</span>
                </div>
            </div>
        </div>

        <div class="row buttons">
            <div>
                <ui-button
                    class="button--wide"
                    text="Register"
                    type="primary"
                    (click)="onSubmit()"
                    [disabled]="
                        newUserForm.invalid || !user.acceptsToC || !selectedUserTitle
                    "></ui-button>
            </div>
        </div>

        <ui-popover
            #passwordRequirementsPopover="ui-popover"
            [config]="uiPopoverConfig">
            <ng-template ui-popover-template>
                <app-password-requirements
                    [checklist]="shownPasswordRequirements"
                    [passwordRequirements]="passwordRequirements"></app-password-requirements>
            </ng-template>
        </ui-popover>
    </form>
</div>
