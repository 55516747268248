function c(t, n = !1) {
  return new Intl.DateTimeFormat("en-GB", {
    year: n ? "numeric" : void 0,
    month: n ? "numeric" : void 0,
    day: n ? "numeric" : void 0,
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: !1
  }).format(new Date(t));
}
var o = t => typeof t > "u",
  i = t => o(t) || t === null,
  r = t => !i(t) && typeof t == "object",
  s = t => {
    if (!r(t)) return !1;
    let n = Object.getPrototypeOf(t);
    if (n === null) return !0;
    let e = Object.prototype.hasOwnProperty.call(n, "constructor") && n.constructor;
    return typeof e == "function" && e instanceof e && Function.prototype.toString.call(e) === Function.prototype.toString.call(Object);
  },
  u = t => t instanceof Error || t instanceof ErrorEvent || r(t) && t.error instanceof ErrorEvent,
  a = t => "status" in t && t.status >= 400;
export { c as a, o as b, i as c, r as d, s as e, u as f, a as g };