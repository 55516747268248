import { a as o } from "./chunk-2ECMQ5XL.js";
var s = window.console.debug;
window.console.debug = () => {};
function L(i, n) {
  o.verbose(`App running in {${i}}. Production: ${n}`), o.verbose(`Log level set to ${o.getLogLevelName()}`);
}
function d({
  level: i,
  production: n,
  sandbox: g,
  stage: l
}) {
  let e = 2;
  switch (g ? e = 3 : n && (e = 4), i) {
    case "debug":
      e = 0, window.console.debug = s;
      break;
    case "log":
      e = 2;
      break;
    case "verbose":
      e = 1;
      break;
    case "warn":
      e = 3;
      break;
  }
  o.setLogLevel(e), L(l, n);
}
export { d as a };