import { Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { LoginComponent, LoginTestComponent } from './login/components';
import { OopsComponent } from './shared/components';
import { InvitationExpiredComponent, RegistrationComponent } from './registration/components';
import { AuthGuard } from '@auth0/auth0-angular';
import { SsoComponent } from './login/components/sso/sso.component';

export const routes: Routes = [
    {
        path: '',
        component: AppComponent
    },
    {
        path: 'test-sso',
        component: SsoComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'login/test',
        component: LoginTestComponent
    },
    {
        path: 'oops',
        component: OopsComponent
    },
    {
        path: 'register',
        component: RegistrationComponent
    },
    {
        path: 'invitation-expired',
        component: InvitationExpiredComponent
    }
];
